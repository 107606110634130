"use client";

import {
  CareerJourneySection,
  ContactSection,
  EducationSection,
  FeatureSection,
  HeroSection,
  PortfolioSection,
  TechStackSection,
  TestimonialSection,
  WorkExperienceSection,
} from "@/components/organisms";
import { BasicLayout } from "@/components/templates";

export default function AboutMePage() {
  return (
    <BasicLayout>
      <HeroSection />
      <TechStackSection />
      <FeatureSection />
      <TestimonialSection />
      <CareerJourneySection />
      <WorkExperienceSection />
      <EducationSection />
      <PortfolioSection />
      <ContactSection />
    </BasicLayout>
  );
}
